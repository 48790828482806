import styled from "styled-components"

export const Style = styled.div`
  .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 51, 0.5);
    z-index: 100;

    @media (max-width: 768px) {
      background: none;
    }
  }

  .downloadModal {
    position: absolute;
    width: 468px;
    height: 558px;
    left: 35%;
    top: 200px;
    border-radius: 8px;
    background-color: #fff;
    color: #000033;
    display: flex;
    flex-direction: column;
    z-index: 101;
    padding: 10px 0 40px 0;

    @media (max-width: 768px) {
      top: 302px;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 550px;
    }
  }

  .downloadModal .header {
    display: flex;
    padding: 0 24px 10px 24px;
    border-bottom: solid 1px #eaeaea;
  }

  .downloadModal .title {
    display: flex;
    color: #000033;
    font-size: 1.25em;
    line-height: 32px;
    font-weight: 700;
    width: 100%;
    justify-content: center;
  }

  .downloadModal .closeBtn {
    display: flex;
    background-color: #fff;
    margin-right: auto;
    font-size: 14px;
    cursor: pointer;
    border: none;
    text-align: center;
    padding-top: 10px;
  }

  .downloadModal .closeIcon {
    fill: #fa4616;
  }

  .downloadModal .body {
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .downloadModal .logo {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    width: 239px;
    height: 134px;
    margin-inline: auto;
  }

  .downloadModal .logoPitpay {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    width: 110px;
    height: 115px;
    margin-inline: auto;

    img {
      object-fit: contain;
      width: 110px;
      height: 115px;
    }
  }

  .downloadModal .text {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
    max-width: 327px;
    margin-inline: auto;
  }

  .downloadModal .text h1 {
    width: 375px;
    text-align: center;
    color: #000;
    font-size: 24px;
    line-height: 34px;
    font-family: "Barlow Condensed";
    font-weight: 700;
    opacity: 0.8;
  }

  .phoneInput {
    display: flex;
    justify-content: center;
    margin: 20px 0px;
  }

  .PhoneInputCountry {
    /* width: 10px; */
    border: 1px solid #bbbbbb;
    border-radius: 8px;
    padding: 13px;
    --PhoneInputCountrySelectArrow-color: #fa4616;
    --PhoneInput-color--focus: #fa4616;
    --PhoneInputCountrySelectArrow-opacity: 1;
  }

  .PhoneInputCountryIcon {
    margin-right: 11px;
  }

  .PhoneInputCountryIconImg {
    scale: 1.25;
  }

  .PhoneInputCountrySelectArrow {
    scale: 2;
  }

  .PhoneInputInput {
    width: 247px;
    height: 56px;
    margin-left: 1px;
    text-align: center;
    color: #000;
    font-size: 42px;
    letter-spacing: 2px;
    font-family: "Barlow Condensed";
    font-weight: 500;
    border: 1px solid #bbbbbb;
    border-radius: 8px;
  }

  .phoneAndEmail {
    font-size: 2em;
    display: flex;
    flex-direction: column;
    font-weight: 700;
    text-align: center;
    margin-bottom: 10px;
    max-width: 90%;
  }

  .content {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    font-size: 1.25em;
    font-weight: 500;
    line-height: 34px;
    max-width: 327px;
    margin-inline: auto;
  }

  .buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 20px;
  }
`

import React from "react"
import styled from "styled-components"

const ButtonWrapper = styled.button.attrs(props => ({
  style: props.inputStyle,
}))`
  background-color: ${props => (props.disabled ? "transparent" : "#fa4616;")};
  min-height: ${props => (props.minHeight ? props.minHeight : "46px")};
  max-height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  flex-shrink: 0;
  border-radius: 8px;
  border-color: transparent;
  position: relative;
  box-shadow: ${props =>
    props.disabled
      ? "none"
      : "0px 6px 2px -4px rgba(14, 14, 44, 0.10000000149011612)"};
  overflow: hidden;
  color: white;
  font-weight: 700;
  font-size: 1em;
  cursor: pointer;
  font-family: "Barlow Condensed";
  color: ${props => (props.disabled ? "#BBBBBB" : "")};
  border: ${props => (props.disabled ? "2px solid #BBBBBB" : "")};

  &:hover {
    background: "linear-gradient(to top, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)) rgb(250, 70, 22)";
  }
`

export default function Button(props) {
  const { text, style, onClick, minHeight, disabled } = props
  return (
    <ButtonWrapper
      inputStyle={style}
      onClick={disabled ? null : onClick}
      minHeight={minHeight}
      disabled={disabled}
    >
      {text}
    </ButtonWrapper>
  )
}

import { useMutation } from "@apollo/react-hooks"
import { gql } from "graphql-tag"
import { generateToken } from "../utils/generateToken";

const SEND_APPSTORE_LINK = gql`
  mutation sendAppstoreLink($input: RequestCodeInput!) {
    sendAppstoreLink(input: $input)
  }
`;

export function useSendLink() {
  const secretKey = generateToken();
  const [sendAppstoreLink] = useMutation(SEND_APPSTORE_LINK, {
    context: {
      headers: {
        secret_key: secretKey
      }
    }
  });
  return sendAppstoreLink;
}

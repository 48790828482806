import jwt from "jsonwebtoken"

export const generateToken = () => {
  const secret = process.env.GATSBY_SMS

  const payload = {
    platform: "iOS",
  }

  const options = {
    // Your options here
    expiresIn: "1h",
  }

  const newToken = jwt.sign(payload, secret, options)

  const randomChars = Math.random()
    .toString(36)
    .substring(7) // Generate 7 random characters
  const finalToken = newToken + randomChars // Concatenate the random characters to the end of the token

  return finalToken
}

import React, { useState } from "react"
import ticketHossLogo from "../images/tickethoss_logo_without_phone.svg"
import PitpayLogo from "../images/Pit Pay Orange .svg"
import closeIcon from "../images/close.svg"
import "react-phone-number-input/style.css"
import PhoneInput from "react-phone-number-input"
import Button from "./Button"
import { Style } from "../styles/downloadApp.style"
import { useSendLink } from "../gql/useSendLink"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

export default function DownloadApp({ onClose }) {
  const isTickethoss = process.env.GATSBY_APP_PLATFORM === "tickethoss"

  const [phoneNumber, setPhoneNumber] = useState()

  const sendLink = useSendLink()

  const onPhoneChange = value => {
    setPhoneNumber(value)
  }

  const onSendLink = async () => {
    if (phoneNumber && phoneNumber.length > 0) {
      const response = await sendLink({
        variables: {
          input: {
            calling_code: phoneNumber.substring(0, 2),
            phone: phoneNumber.substring(2),
          },
        },
      })

      if (response && response.data.sendAppstoreLink) {
        onClose()
        toast.success("Message Sent Successfully")
      } else {
        toast.error("Error sendind message")
      }
    }
  }

  return (
    <Style>
      <div className="overlay" onClick={onClose}></div>

      <div className="downloadModal">
        <div className="header">
          <img
            src={closeIcon}
            alt="Close Icon"
            onClick={onClose}
            style={{ cursor: "pointer" }}
            height={24}
            width={24}
          />
          <div className="title">
            Download {isTickethoss ? "Ticket Hoss" : "Pit Pay"} App
          </div>
        </div>

        <div className="body">
          {isTickethoss ? (
            <div className="logo">
              <img src={ticketHossLogo} alt="ticket-hoss logo" />
            </div>
          ) : (
            <div className="logoPitpay">
              <img src={PitpayLogo} alt="pitpay logo" />
            </div>
          )}
          <div className="text">
            Type your mobile phone number to download the app.
          </div>
          <div className="phoneInput">
            <PhoneInput
              defaultCountry="US"
              placeholder="111 222 3344"
              value={phoneNumber}
              onChange={onPhoneChange}
            />
          </div>
          <Button
            text="Send Download Link by SMS"
            style={{
              width: 327,
              height: 56,
              marginInline: "auto",
              fontSize: 24,
              lineHeight: "34px",
              fontWeight: 700,
            }}
            onClick={onSendLink}
          />
        </div>
      </div>
    </Style>
  )
}
